import classes from './style.module.css';
import { Box } from '@mantine/core';
import { RFC } from '@app/ui/type';
import { Props } from './type';


export const Panel : RFC<Props> = ({
    children, shadow : _shadow = 'normal',
}) =>
    <Box className={classes.panel}>
        <Box className={classes.content}>
            {children}
        </Box>
    </Box>
