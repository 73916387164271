import { CoreActionHandler } from '@app/type/framework/core/CoreActionHandler';
import { BaseItemProps } from './type';
import { Box } from '@mantine/core';
import { RFC } from '@app/ui/type';


export const BaseItem : RFC<BaseItemProps> = ({
    children, onChangeSelection, hash, name, index, id,
}) => {

    // const { classes } = useStyles();

    const handleClick = () : CoreActionHandler =>
        onChangeSelection({ name, hash, index, id });

    return (
        <Box onClick={handleClick}>
            {children}
        </Box>
    );
};
