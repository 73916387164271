import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import { Orientation } from '@app/constant/framework/Orientation';
import { CoreModel } from '@app/type/framework/core/CoreModel';
import { BaseItem } from './BaseItem';
import { Props } from './type';


export const BaseCollection = <T extends CoreModel>({
    onChangeSelection, renderItem,onReorder, items, name,
} : Props<T>) : React.ReactNode => {

    return (
        <DragDropContext
            onDragEnd={({ source, destination  }) => {
                if(onReorder)
                    onReorder(source.index, destination?.index);
                else
                    console.log('onReorder is not defined')
            }}>
            <Droppable
                droppableId='dnd-list'
                direction={Orientation.Vertical}>
                {provided => (
                    <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}>
                        {items.map((item, index) =>
                            <Draggable
                                key={item.hash}
                                index={index}
                                draggableId={item.hash as string}>
                                {(provided, _snapshot) => (
                                    <div
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        ref={provided.innerRef}>
                                        <BaseItem
                                            onChangeSelection={onChangeSelection}
                                            hash={item.hash as string}
                                            key={item.hash}
                                            index={index}
                                            name={name}>
                                            {renderItem(item as T)}
                                        </BaseItem>
                                    </div>
                                )}
                            </Draggable>,
                        )}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
}
