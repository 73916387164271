import { Breadcrumbs, Anchor, Text } from '@mantine/core';
import { useId } from '@mantine/hooks';
import { RFC } from '@app/ui/type';
import { Props } from './type';
import Link from 'next/link';


export const Breadcrumb : RFC<Props> = ({
    value : itemList,
}) => {

    const uuid = useId();

    return (
        <Breadcrumbs
            separator='→'
            mt='xs'>
            {itemList.map((item, index) => {

                if(!item.href)
                    return item.title;

                if(item.href)
                    return (
                        <Anchor
                            id={`${uuid}-${index}`}
                            href={item.href}
                            key={index}
                            component={Link}
                            aria-current={
                                index === itemList.length - 1
                                    ? 'page'
                                    : undefined}>
                            <Text size='lg'>
                                {item.title}
                            </Text>
                        </Anchor>
                    );
            })}
        </Breadcrumbs>
    );
};
