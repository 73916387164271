import { Group, Title } from '@mantine/core';
import { NoResultsProps } from './type';
import { RFC } from '@app/ui/type';


export const NoResults : RFC<NoResultsProps> = ({
    noItemsContent,
}) => {
    const render = () : React.ReactNode => {

        if (!noItemsContent)
            return (
                <Title
                    order={4}
                    fw={200}>
                    {'None'}
                </Title>
            )

        if (typeof noItemsContent === 'string')
            return (
                <Title
                    order={4}
                    fw={200}>
                    {noItemsContent}
                </Title>
            )

        return noItemsContent;
    };

    return (
        <Group
            justify='center'
            mt='xl'>
            {render()}
        </Group>
    );
};
