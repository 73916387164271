import { DialogModalStateType } from '@app/constant/enum/ui/DialogModalStateTypeEnum';
import { Logic, isComposeClosedAndNotEmpty, isComposeOpen  } from './Logic';
import { Box, Button, Container, Group, Stack } from '@mantine/core';
import { ChangeSelection } from '@app/type/control/ChangeSelection';
import { BaseCollection } from '@app/ui/collection/BaseCollection';
import { selectIsInitalizeComplete } from '@app/client/selector';
import { ScreenSize } from '@app/constant/framework/ScreenSize';
import { CoreModel } from '@app/type/framework/core/CoreModel';
import { useDispatch, useSelector } from '@app/client/hook';
import { ComposeModel } from '@app/ui/control/ComposeModel';
import { CA } from '@app/type/framework/core/CoreAction';
import { TitleField } from '@app/ui/control/TitleField';
import React, { useContext } from 'react';
import { App } from '@app/ui/AppContext';
import { NoResults } from './NoResults';
import { type Props } from './type';
import { Loading } from './Loading';
import { RFC } from '@app/ui/type';
import { Action } from '@app/client/action';


export const BrowseModelLayout : RFC<Props<CoreModel>> = ({
    dialogModalStateType, noItemsContent = 'None',
    relationshipConfig,   headerComponent,
    composingModel,       newButtonContent,
    renderCompose,        headerRight,
    renderItem,           onNewModal,
    modelList,            modelType,
    onReorder,            title,
}) => {
    const dispatch = useDispatch();

    const isInitalizeComplete = useSelector(selectIsInitalizeComplete);

    const isComposeClosedAndEmpty = Logic.isComposeClosedAndEmpty(dialogModalStateType, modelList)

    const { screenSize } = useContext(App);

    const onChangeSelection = (changeSelection : ChangeSelection) : CA =>
        dispatch(Action.selectModelListItem({
            changeSelection,
            modelType,
        }));

    return (
        <Container fluid={true}>
            <Stack>
                <Group justify='space-between'>
                    {title && title.trim() !== '' &&
                        <TitleField>
                            {title}{}
                        </TitleField>
                    }
                    {dialogModalStateType === DialogModalStateType.Closed && newButtonContent &&
                        <Button
                            aria-label='New'
                            onClick={onNewModal}
                            variant='subtle'
                            size={screenSize === ScreenSize.Phone
                                ? 'xs'
                                : 'xl'
                            }>
                            {newButtonContent}
                        </Button>
                    }
                    {headerRight}
                </Group>
                {headerComponent}
            </Stack>
            {isComposeClosedAndEmpty && isInitalizeComplete &&
                <NoResults noItemsContent={noItemsContent} />
            }
            {isComposeClosedAndEmpty && !isInitalizeComplete &&
                <Loading />
            }
            {isComposeClosedAndNotEmpty(dialogModalStateType, modelList) &&
                <Box mt='md'>
                    <BaseCollection
                        onChangeSelection={onChangeSelection}
                        renderItem={renderItem}
                        onReorder={onReorder}
                        items={modelList}
                        name={modelType} />
                </Box>
            }
            {isComposeOpen(dialogModalStateType) && (
                <ComposeModel
                    relationshipConfig={relationshipConfig}
                    composingModel={composingModel}
                    renderCompose={renderCompose} />
            )}
        </Container >
    );
};
