import { PathwayStatusType } from '@app/constant/enum/model/PathwayStatusTypeEnum';
import { ModelType } from '@app/constant/enum/model/ModelTypeEnum';
import { CoreModel } from '@app/type/framework/core/CoreModel';
import { PathwayModel } from '@app/type/model/PathwayModel';
import classes from './style.module.css';
import { RFC } from '@app/ui/type';
import { Props } from './type';
import Link from 'next/link';


export const RouteLink : RFC<Props<CoreModel>> = ({
    model, children,
}) => {

    let linkPrefix = model.modelType.toLocaleLowerCase();

    if(model.modelType === ModelType.Asset)
        linkPrefix = 'document'

    if(model.modelType === ModelType.Pathway) {
        const status = (model as PathwayModel).status;

        switch(status) {
            case PathwayStatusType.Published: linkPrefix = 'patent/published'; break;
            case PathwayStatusType.Enrolled : linkPrefix = 'patent/enrolled';  break;
            case PathwayStatusType.Draft    : linkPrefix = 'patent-template';  break;

            default: linkPrefix = 'pathway';
        }
    }

    return (
        <Link
            href={`/${linkPrefix}/${model.hash}`}
            className={classes.text}>
            {children}
        </Link>
    );
};
