import { DialogModalStateType } from '@app/constant/enum/ui/DialogModalStateTypeEnum';
import { ComposeDialogType } from '@app/constant/enum/ui/ComposeDialogTypeEnum';
import { CoreModel } from '@app/type/framework/core/CoreModel';


type IsDialogState = (dialogModalStateType : DialogModalStateType) => boolean;

export const isDialogClosed : IsDialogState = (
    dialogModalStateType : DialogModalStateType,
) : boolean =>
    dialogModalStateType === DialogModalStateType.Closed;

export const isDialogOpen : IsDialogState = (
    dialogModalStateType : DialogModalStateType,
) : boolean =>
    dialogModalStateType === DialogModalStateType.Open;

export const isDialogClosedAndNotEmpty = <T extends CoreModel,>(
    isDialogClosed : IsDialogState,
    modelList : T[],
    dialogModalStateType : DialogModalStateType,
) : boolean =>
    isDialogClosed(dialogModalStateType) && isModelListNotEmpty(modelList);

export const isDialogClosedAndEmpty = <T extends CoreModel,>(
    isDialogClosed : IsDialogState,
    modelList : T[],
    dialogModalStateType : DialogModalStateType,
) : boolean =>
    isDialogClosed(dialogModalStateType) && isModelListEmpty(modelList);

export const isComposeInline = (composeDialogType : ComposeDialogType) : boolean =>
    composeDialogType === ComposeDialogType.Inline;

export const isComposePopup = (composeDialogType : ComposeDialogType) : boolean =>
    composeDialogType === ComposeDialogType.Popup;

export const isOpenAndInline = (
    dialogModalStateType : DialogModalStateType,
    composeDialogType    : ComposeDialogType,
) : boolean =>
    isDialogOpen(dialogModalStateType) && isComposeInline(composeDialogType);

export const isOpenAndPopup = (
    dialogModalStateType : DialogModalStateType,
    composeDialogType    : ComposeDialogType,
) : boolean =>
    isDialogOpen(dialogModalStateType) && isComposePopup(composeDialogType);

export const isModelListNotEmpty = <T extends CoreModel,>(
    modelList : T[],
) : boolean =>
    modelList.length > 0;

export const isModelListEmpty = <T extends CoreModel,>(
    modelList : T[],
) : boolean =>
    modelList.length === 0;

export const isComposeClosed = (
    dialogModalStateType : DialogModalStateType,
) : boolean =>
    dialogModalStateType === DialogModalStateType.Closed;
export const isComposeOpen   = (
    dialogModalStateType : DialogModalStateType,
) : boolean =>
    dialogModalStateType === DialogModalStateType.Open;

export const isComposeClosedAndNotEmpty = (
    dialogModalStateType : DialogModalStateType,
    modelList : CoreModel[],
) : boolean => isComposeClosed(dialogModalStateType)
&& isModelListNotEmpty(modelList);

const isComposeClosedAndEmpty = (
    dialogModalStateType : DialogModalStateType,
    modelList : CoreModel[],
) : boolean => isComposeClosed(dialogModalStateType)
    && isModelListEmpty(modelList);


export const Logic = {
    isComposeClosedAndNotEmpty,
    isDialogClosedAndNotEmpty,
    isComposeClosedAndEmpty,
    isDialogClosedAndEmpty,
    isModelListNotEmpty,
    isModelListEmpty,
    isComposeInline,
    isOpenAndInline,
    isComposeClosed,
    isDialogClosed,
    isComposePopup,
    isOpenAndPopup,
    isComposeOpen,
    isDialogOpen,
};
