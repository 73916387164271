import { LoadingSpinner } from '@app/ui/static/LoadingSpinner';
import { RFC } from '@app/ui/type';
import { Stack, Title } from '@mantine/core';


export const Loading : RFC = () =>
    <Stack
        mt='xl'
        align='center'
        justify='center'>
        <Title fw={200}>
            {'Loading'}
        </Title>
        <LoadingSpinner />
    </Stack>
