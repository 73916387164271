import { IconDragDrop } from '@tabler/icons-react';
import { Grid, Text } from '@mantine/core';
import { RFC } from '@app/ui/type';
import { Props } from './type';


export const NoContent : RFC<Props> = ({ title }) =>
    <Grid>
        <Grid.Col span={1}>
            <IconDragDrop size={30} />
        </Grid.Col>
        <Grid.Col
            span={11}
            p='lg'>
            <Text
                c='dimmed'
                size='xl'
                inline={true}>
                {title && (
                    <>
                        {title}
                    </>
                )}
                {!title &&
                    'Drag documents here or click to select files'
                }
            </Text>
            <Text
                size='sm'
                c='dimmed'
                inline={true}
                mt={7}>
                {'Attach as many files as you like'}
            </Text>
        </Grid.Col>
    </Grid>;
