import { CoreModel } from '@app/type/framework/core/CoreModel';
import { Button, Grid, Group, Paper } from '@mantine/core';
import { useDispatch } from '@app/client/hook';
import { ComposeModelProps } from './type';
import { App } from '@app/ui/AppContext';
import { useContext } from 'react';
import { RFC } from '@app/ui/type';
import { CA } from '@app/type/framework/core/CoreAction';
import { Action } from '@app/client/action';


export const ComposeModel : RFC<ComposeModelProps<CoreModel>> = ({
    renderCompose, composingModel, relationshipConfig,
}) => {

    const dispatch = useDispatch();

    const handleDiscard = () : CA => dispatch(Action.closeNewModelDialog(composingModel.modelType));

    const handleCreate = () : CA => {

        dispatch(Action.createModel({
            relationshipConfig,
            model : composingModel,
        }));

        return dispatch(Action.closeNewModelDialog(composingModel.modelType));
    }

    const { isPhone } = useContext(App);

    return (
        <Grid>
            <Grid.Col span={{ base : 12 }}>
                <Paper
                    withBorder={isPhone}
                    radius='md'
                    mt='md'
                    mb='lg'
                    p='md'>
                    {renderCompose}
                </Paper>
                <Group justify='right'>
                    <Button
                        onClick={handleDiscard}
                        aria-label='Discard'
                        variant='outline'
                        size='xs'>
                        {'Discard'}
                    </Button>
                    <Button
                        aria-label='Create'
                        onClick={handleCreate}
                        size='xl'>
                        {'Create'}
                    </Button>
                </Group>
            </Grid.Col>
        </Grid>
    );
}
