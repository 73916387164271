import { Text, Box } from '@mantine/core';
import { Props } from './type';
import { RFC } from '@app/ui/type';
import { Panel } from '../Panel';


export const PanelPlaceholder : RFC<Props> = ({ label }) =>
    <Box>
        <Panel>
            <Text>
                {label}
            </Text>
        </Panel>
    </Box>
