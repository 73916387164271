import { Text, rem } from '@mantine/core';
import { PropsWithChildren } from 'react';
import { RFC } from '@app/ui/type';


export const TitleField : RFC<PropsWithChildren> = ({
    children,
}) =>
    <Text
        c='#030'
        fw={300}
        size={rem(40)}>
        {children}
    </Text>
