import { Paper, SimpleGrid, Title, Text, rem, Box } from '@mantine/core';
import { ModelType } from '@app/constant/enum/model/ModelTypeEnum';
import { Dropzone, type FileWithPath } from '@mantine/dropzone';
import { LoadingSpinner } from '@app/ui/static/LoadingSpinner';
import { CoreModel } from '@app/type/framework/core/CoreModel';
import { CoreFile } from '@app/type/framework/core/CoreFile';
import { P } from '@app/type/framework/data/P';
import { RestApi } from '@app/client/RestApi';
import classes from './style.module.css';
import { useId } from '@mantine/hooks';
import '@mantine/dropzone/styles.css';
import { RFC } from '@app/ui/type';
import { useState } from 'react';
import { Logic } from './Logic';
import { Props } from './type';
import Image from 'next/image';


export const FileUploader : RFC<Props<CoreModel>> = ({
    value : parentModel, onUploadComplete,
    onChange, children, label,
}) => {

    if (!Logic.isValidParentModelType(parentModel))
        throw Error(`FileUploader only accepts Matter models for now, invalid type (${parentModel.modelType}))`);

    // add state for uploading
    const [isUploading, setIsUploading ] = useState<boolean>(false);

    const id = useId();

    const [fileList, setFileList] = useState<FileWithPath[]>([]);

    const previews = fileList.map((file, index) => {

        const imageUrl = URL.createObjectURL(file);

        return (
            <Paper
                key={`${id}-${index}`}
                shadow='sm'
                p='xl'>
                <Image
                    alt={`${file.name} AND ${file.path}`}
                    width={100}
                    height={100}
                    src={imageUrl} />
            </Paper>
        );
    });

    const handleDrop = async (acceptedFileList : FileWithPath[]) : P => {

        setIsUploading(true);

        setFileList(acceptedFileList);

        acceptedFileList.forEach(file => {

            const reader = new FileReader();

            reader.onload = async evt => {

                const pendingCoreFile : CoreFile = {
                    data : evt.target!.result as string,
                    path : file.path ?? '[no path]',
                    date : file.lastModified,
                    name : file.name,
                    hash : parentModel.hash,
                    size : file.size,
                    type : file.type,
                };

                let modelAndStash  = null;

                switch (parentModel.modelType)  {
                    case ModelType.Pathway : modelAndStash = await RestApi.pathway.upload(parentModel.hash, pendingCoreFile); break;
                    case ModelType.Contact : modelAndStash = await RestApi.contact.upload(parentModel.hash, pendingCoreFile); break;
                    case ModelType.Message : modelAndStash = await RestApi.message.upload(parentModel.hash, pendingCoreFile); break;
                    case ModelType.Asset   : modelAndStash = await RestApi.asset.upload(  parentModel.hash, pendingCoreFile); break;
                    case ModelType.Task    : modelAndStash = await RestApi.task.upload(   parentModel.hash, pendingCoreFile); break;

                    default:
                        throw Error(`Invalid Upload (${parentModel.modelType}))`);
                }

                const { model, modelStash } = modelAndStash;

                setIsUploading(false);

                onChange && onChange(model);
                onUploadComplete(model, modelStash);
            };

            reader.readAsDataURL(file);
        });
    };

    return (
        <Dropzone
            className={classes.dropzone}
            onDrop={handleDrop}
            id={id}>

            <Title order={3}>
                {label}
            </Title>

            <Dropzone.Accept>
                <Text
                    ta='center'
                    fw='bold'
                    fz={rem(30)}>
                    {'Drop to Upload'}
                </Text>
            </Dropzone.Accept>

            <Dropzone.Reject>
                {'Dropzone.Reject'}
            </Dropzone.Reject>

            {isUploading &&
                <Dropzone.Idle>
                    <Box pb='xl'>
                        <LoadingSpinner />
                    </Box>
                </Dropzone.Idle>
            }
            {!isUploading &&
                <Dropzone.Idle>
                    {children}
                    {!children &&
                        <SimpleGrid
                            cols={4}
                            mt={previews.length > 0 ? 'xl' : 0}>
                            {previews}
                        </SimpleGrid>
                    }
                </Dropzone.Idle>
            }
        </Dropzone>
    );
};
