import { ModelType } from '@app/constant/enum/model/ModelTypeEnum';


export const AcceptedModelTypeList = [
    ModelType.Location,
    ModelType.Pathway,
    ModelType.Contact,
    ModelType.Message,
    ModelType.Event,
    ModelType.Asset,
    ModelType.Task,
];
